import { 
  NonRecoverable, 
  Recoverable, 
  totalRecoverable as calculateTR ,
  totalNonRecoverable as calculateTNR
} from "../../utils"

export function calculateFreeholdCapOnPerpetuity(
  effectiveRent: number,
  item: Recoverable & NonRecoverable,
  capitalRate: number,
  purchaserCost: number,
  timingId: number
) {
  const totalRecoverable = calculateTR(item) * 12

  const totalNonRecoverable = calculateTNR(item) * 12

  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const yieldPurchaseOnPerpetuityAtCapitalRate = timingId === 1
    ? yieldPurchaseOnPerpetuityAnnually(capitalRate)
    : timingId === 2
      ? yieldPurchaseOnPerpetuityQuarterly(capitalRate)
      : undefined

  const capitalisedRent = yieldPurchaseOnPerpetuityAtCapitalRate * passingRent

  const totalPurchaserCost = (purchaserCost/100)  * capitalisedRent
  const value = capitalisedRent - totalPurchaserCost

  return {
    effectiveRent,
    passingRent,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: item.recoverablePropertyManagement * 12,
      recoverableLeasingExpenses: item.recoverableLeasingExpenses * 12,
      recoverableUtilities: item.recoverableUtilities * 12,
      recoverableMaintenance: item.recoverableMaintenance * 12,
      recoverableInsurance: item.recoverableInsurance * 12,
      recoverableJanitorial: item.recoverableJanitorial * 12,
      recoverablePropertyTaxes: item.recoverablePropertyTaxes * 12,
      recoverableBusiness: item.recoverableBusiness * 12,
      recoverableOthers: item.recoverableOthers * 12
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: item.nonRecoverablePropertyManagement * 12,
      nonRecoverableLeasingExpenses: item.nonRecoverableLeasingExpenses * 12,
      nonRecoverableUtilities: item.nonRecoverableUtilities * 12,
      nonRecoverableMaintenance: item.nonRecoverableMaintenance * 12,
      nonRecoverableInsurance: item.nonRecoverableInsurance * 12,
      nonRecoverableJanitorial: item.nonRecoverableJanitorial * 12,
      nonRecoverablePropertyTaxes: item.nonRecoverablePropertyTaxes * 12,
      nonRecoverableBusiness: item.nonRecoverableBusiness * 12,
      nonRecoverableOthers: item.nonRecoverableOthers * 12
    },
    yieldPurchaseOnPerpetuityAtCapitalRate,
    capitalisedRent: isNaN(capitalisedRent) ? undefined : capitalisedRent,
    totalPurchaserCost: isNaN(totalPurchaserCost) ? undefined : totalPurchaserCost,
    value: isNaN(value) ? undefined : value,
  }
}

function yieldPurchaseOnPerpetuityAnnually(capitalRate: number) {
  const rateInVal = capitalRate / 100
  if (rateInVal === 0) return undefined
  return 1 / rateInVal
}

function yieldPurchaseOnPerpetuityQuarterly(capitalRate: number) {
  const rateInVal = capitalRate / 100
  let temp = 1 + rateInVal
  temp = Math.pow(temp, -0.25)
  temp = 1 - temp
  if (temp === 0) return undefined
  return 1 / (4 * temp)
}