import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _authService: MadAuthService,
    private _renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    const planUid = this._route.snapshot.queryParamMap.get('planUid') as string
    const planPaymentTerm = this._route.snapshot.queryParamMap.get('planPaymentTerm') as string
    if (planUid && planPaymentTerm) {
      this._authService.loadRegisterSpecificScript(this._renderer2, planUid, planPaymentTerm)
    } else {
      this._authService.loadRegisterScript(this._renderer2)
    }
  }

}
