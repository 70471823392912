import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MadLogin } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';

@Component({
  selector: 'kt-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss']
})
export class PostLoginComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.loadScript().then(() => {
      console.log(window['Outseta'])
      const token = this._route.snapshot.queryParamMap.get('access_token') as string
      if (token) {
        this._store$.dispatch(new MadLogin({token}))
      }
    })
  }

  loadScript() {
    // TODO: refactor
    return new Promise((resolve, reject) => {
      if (document.getElementById('outseta_js_options') != null) {
        document.getElementById('outseta_js_options').remove();
      }
      let script_option = document.createElement('script');
      script_option.id = 'outseta_js_options';
      script_option.text = `
        var o_options = {
          "domain": "interval.outseta.com",
          "monitorDom": true,
          "load": "auth,profile,support",
        }
      `

      if (document.getElementById('outseta_js') != null) {
        document.getElementById('outseta_js').remove();
      }
      let node = document.createElement('script');
      node.src = 'https://cdn.outseta.com/outseta.min.js';
      node.dataset.options = 'o_options';
      node.type = 'text/javascript';
      node.onload = function () {
        console.log('outseta loaded into the dom',);
      }
      // node.async = true;
      node.id = 'outseta_js';
      node.onload = () => {
        resolve({ script: name, loaded: true, status: 'Loaded' });
      };
      node.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script_option);
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }
}
