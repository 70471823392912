import { durationCalculation } from "../../../valuation";
import { Lease, PropertyRevenue } from "../types";
import { HeadlineRent } from "../types/headline-rent";

export function mapLeaseToHeadlineRent(lease: Lease): HeadlineRent {
  return {
    currency: lease.currency,
    size: lease.leasedArea,

    startDate: lease.startDate,
    duration: durationCalculation(lease.duration, lease.durationType),
    rentFreePeriod: durationCalculation(lease.rentFreePeriod, lease.durationType),
    fittingOutPeriod: durationCalculation(lease.fittingOutPeriod, lease.durationType),
    writeOffPeriodAfter: durationCalculation(lease.writeOffPeriodAfter, lease.durationType),
    breakOptionAfter: durationCalculation(lease.breakOptionAfter, lease.durationType),
    unexpiredLeaseTerms: lease.unexpiredLeaseTerms,

    capitalPayment: lease.capitalPayment,

    rentInputAmount: lease.rentInputAmount,
    rentInputAmounType: lease.rentInputAmountType,
    rentBasis: lease.rentBasis,
    rentReviewCycle: durationCalculation(lease.rentReviewCycle, lease.durationType),
    rentReviewType: lease.rentReviewType,

    recoverableBusiness: lease.recoverableBusiness,
    recoverableInsurance: lease.recoverableInsurance,
    recoverableJanitorial: lease.recoverableJanitorial,
    recoverableLeasingExpenses: lease.recoverableLeasingExpenses,
    recoverableMaintenance: lease.recoverableMaintenance,
    recoverableOthers: lease.recoverableOthers,
    recoverablePropertyManagement: lease.recoverablePropertyManagement,
    recoverablePropertyTaxes: lease.recoverablePropertyTaxes,
    recoverableUtilities: lease.recoverableUtilities,
    nonRecoverableBusiness: lease.nonRecoverableBusiness,
    nonRecoverableInsurance: lease.nonRecoverableInsurance,
    nonRecoverableJanitorial: lease.nonRecoverableJanitorial,
    nonRecoverableLeasingExpenses: lease.nonRecoverableLeasingExpenses,
    nonRecoverableMaintenance: lease.nonRecoverableMaintenance,
    nonRecoverableOthers: lease.nonRecoverableOthers,
    nonRecoverablePropertyManagement: lease.nonRecoverablePropertyManagement,
    nonRecoverablePropertyTaxes: lease.nonRecoverablePropertyTaxes,
    nonRecoverableUtilities: lease.nonRecoverableUtilities
  }
}

export function mapPropertyRevenueToHeadlineRent(lease: PropertyRevenue, tpSize: number, totalLeasedArea: number): HeadlineRent {
  return {
    currency: lease.currency,
    size: lease.size === 'total_lease' ? totalLeasedArea : tpSize,

    startDate: lease.startDate,
    duration: durationCalculation(lease.duration, lease.durationType),
    rentFreePeriod: durationCalculation(lease.rentFreePeriod, lease.durationType),
    fittingOutPeriod: durationCalculation(lease.fittingOutPeriod, lease.durationType),
    writeOffPeriodAfter: durationCalculation(lease.writeOffPeriodAfter, lease.durationType),
    breakOptionAfter: durationCalculation(lease.breakOptionAfter, lease.durationType),
    unexpiredLeaseTerms: lease.unexpiredLeaseTerms,

    capitalPayment: lease.capitalPayment,

    rentInputAmount: lease.rentInputAmount,
    rentInputAmounType: lease.rentInputAmountType,
    rentBasis: lease.rentBasis,
    rentReviewCycle: durationCalculation(lease.rentReviewCycle, lease.durationType),
    rentReviewType: lease.rentReviewType,

    recoverableBusiness: lease.recoverableBusiness,
    recoverableInsurance: lease.recoverableInsurance,
    recoverableJanitorial: lease.recoverableJanitorial,
    recoverableLeasingExpenses: lease.recoverableLeasingExpenses,
    recoverableMaintenance: lease.recoverableMaintenance,
    recoverableOthers: lease.recoverableOthers,
    recoverablePropertyManagement: lease.recoverablePropertyManagement,
    recoverablePropertyTaxes: lease.recoverablePropertyTaxes,
    recoverableUtilities: lease.recoverableUtilities,
    nonRecoverableBusiness: lease.nonRecoverableBusiness,
    nonRecoverableInsurance: lease.nonRecoverableInsurance,
    nonRecoverableJanitorial: lease.nonRecoverableJanitorial,
    nonRecoverableLeasingExpenses: lease.nonRecoverableLeasingExpenses,
    nonRecoverableMaintenance: lease.nonRecoverableMaintenance,
    nonRecoverableOthers: lease.nonRecoverableOthers,
    nonRecoverablePropertyManagement: lease.nonRecoverablePropertyManagement,
    nonRecoverablePropertyTaxes: lease.nonRecoverablePropertyTaxes,
    nonRecoverableUtilities: lease.nonRecoverableUtilities
  }
}