<div class="kt-login-head kt_login_wrapper">
  <span class="kt-login-signup-label">Already has an account?</span>&nbsp;&nbsp;
  <a href="/mad-auth/login" class="kt-link kt-login-signup-link">
    Login
  </a>
</div>

<div class="kt-login-body">
  <!--begin::Signin-->
  <div class="kt-login-form">
    <h1>
      <a href="https://www.interval-soft.com/" title="Login Page">
        Register Page
      </a>
    </h1>

    <div class="mad-login-form">
      <div id="signup-embed"></div>
    </div>
  </div>
</div>