import { CashFlowMethodData, CFMEffectiveRent, CFMFirstTerm, CFMLastTerm, CFMMidTerm, CFMPreliminary } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/cf-method-types"
import { calculatePresentValueAtPercentRate, calculateYearsPurchaseAtPercentRate, roundTo4Decimals } from "../../../../valuation/utils"
import { HeadlineRent } from "../../types"
import { calculateHeadlineRent } from "./_headline-rent"

export function calculateCashFlowMethod(headlineRent: HeadlineRent, growthRate: number, targetRate: number) {
  const { numberOfTerms, numberOfTermsReal, remainingYears } = calculateNumberOfTerms(headlineRent)

  const method = headlineRent.writeOffPeriodAfter !== 0 && headlineRent.writeOffPeriodAfter < headlineRent.duration
    ? 'write-off-is-lesser-than-lease'
    : 'write-off-is-equal-to-lease-or-unknown'

  const preliminary = calculateCFMPreliminary(headlineRent, method, targetRate)
  const firstTerm = calculateCFMFirstTerm(headlineRent, targetRate)
  const lastTerm = calculateCFMLastTerm(headlineRent, growthRate, targetRate, numberOfTerms, remainingYears)
  const midTerms = calculateMidTerms(headlineRent, numberOfTerms - 2, growthRate, targetRate)

  let factorValue = firstTerm.factorValue + lastTerm.factorValueOfRevisionaryRent
  midTerms.forEach(item => {
    factorValue += item.factorValueOfRevisionaryRent
  })

  const effectiveRent = preliminary.capitalValueOfInducements / factorValue;
  const effectiveRents: CFMEffectiveRent[] = [];
  let prevER = effectiveRent;
  for (let i = 0; i < (numberOfTerms - 1); i++) {
    const val = calculateEffectiveRent(prevER, growthRate, headlineRent.rentReviewCycle);
    const [suffix, prevSuffix] = getSuffixes(i + 1);
    const er: CFMEffectiveRent = {
      prev: prevER,
      value: val,
      termSuffix: suffix,
      prevSuffix: prevSuffix,
      isSelected: false,
    }
    effectiveRents.push(er);
    prevER = er.value;
  }

  const _preliminary = {...preliminary, headlineRent: preliminary.headlineRentValue}
  const rounded = roundCFMResult({
    numberOfTerms,
    numberOfTermsReal,
    remainingYears,
    preliminary: _preliminary,
    firstTerm,
    lastTerm,
    midTerms,
    factorValue,
    results: {
      effectiveRent,
      effectiveRents,
      isSelected: false,
    }
  }) 
  const converted = convertNanToUndefinedCFM(rounded);
  return converted;
}

export function calculateNumberOfTerms(headlineRent: HeadlineRent) {
  const method = headlineRent.writeOffPeriodAfter !== 0 && headlineRent.writeOffPeriodAfter < headlineRent.duration
    ? 'write-off-is-lesser-than-lease'
    : 'write-off-is-equal-to-lease-or-unknown'

  let numberOfTerms: number = undefined
  let numberOfTermsReal: number = undefined
  let remainingYears: number = undefined

  if (method === 'write-off-is-lesser-than-lease') {
    [numberOfTerms, numberOfTermsReal, remainingYears] = getNumOfTermsAndRemainingYears(headlineRent.writeOffPeriodAfter, headlineRent.rentReviewCycle)
  } else {
    [numberOfTerms, numberOfTermsReal, remainingYears] = getNumOfTermsAndRemainingYears(headlineRent.duration, headlineRent.rentReviewCycle)
  }

  return {
    numberOfTerms,
    numberOfTermsReal,
    remainingYears
  }
}

function getNumOfTermsAndRemainingYears(writeOffOrLease: number, rentReview: number): [number, number, number] {
  const realValue = writeOffOrLease / rentReview;
  const numberOfTerms = Math.ceil(realValue);
  const remainder = writeOffOrLease % rentReview;
  if (remainder === 0) {
    return [numberOfTerms, realValue, rentReview];
  }
  return [numberOfTerms, realValue, remainder];
}

function calculateCFMPreliminary(
  headlineRent: HeadlineRent, 
  method: 'write-off-is-lesser-than-lease' | 'write-off-is-equal-to-lease-or-unknown',
  targetRate: number
) {
  const headlineRentValue = calculateHeadlineRent(
    headlineRent.rentInputAmount,
    headlineRent.rentInputAmounType,
    headlineRent.rentBasis?.id,
    headlineRent.size
  )
  const rentReceivedFor = method === 'write-off-is-lesser-than-lease' 
    ? headlineRent.writeOffPeriodAfter - headlineRent.rentFreePeriod
    : headlineRent.duration - headlineRent.rentFreePeriod

  const yearsPurchaseOfRentReceivedFor = calculateYearsPurchaseAtPercentRate(targetRate, rentReceivedFor);
  const presentValueOfRentFreePeriod = calculatePresentValueAtPercentRate(targetRate, headlineRent.rentFreePeriod);
  const capitalValueOfHeadlineRent = headlineRentValue * yearsPurchaseOfRentReceivedFor * presentValueOfRentFreePeriod;
  const capitalValueOfInducements = capitalValueOfHeadlineRent - headlineRent.capitalPayment;
  return {
    headlineRentValue,
    rentReceivedFor,
    yearsPurchaseOfRentReceivedFor,
    presentValueOfRentFreePeriod,
    capitalValueOfHeadlineRent,
    capitalValueOfInducements,
  };
}

function calculateCFMFirstTerm(
  headlineRent: HeadlineRent,
  targetRate: number
) {
  const yp = calculateYearsPurchaseAtPercentRate(targetRate, headlineRent.rentReviewCycle);
  const pv = calculatePresentValueAtPercentRate(targetRate, headlineRent.fittingOutPeriod);
  const factor = yp * pv;
  return {
    yearsPurchaseOfRentReviewCycle: yp,
    presentValueOfFittingOutPeriod: pv,
    factorValue: factor
  }
}

function calculateCFMLastTerm(
  headlineRent: HeadlineRent,
  growthRate: number,
  targetRate: number,
  currentTerm: number,
  lastTermPeriod: number
) {
  const revision = calculateRevisionToFutureValue(growthRate, headlineRent.rentReviewCycle, currentTerm);
  const yp = calculateYearsPurchaseAtPercentRate(targetRate, lastTermPeriod);
  const year = headlineRent.rentReviewCycle * (currentTerm - 1);
  const pv = calculatePresentValueAtPercentRate(targetRate, year);
  const factor = revision * yp * pv;
  const [termSuffix, prevTermSuffix] = getSuffixes(currentTerm);
  return {
    year,
    termSuffix,
    prevTermSuffix,
    revisionToFutureRentalValue: revision,
    yearsPurchaseOfLastTermPeriod: yp,
    presentValueOfRentReviewCycle: pv,
    factorValueOfRevisionaryRent: factor,
  }
}

function calculateMidTerms(
  headlineRent: HeadlineRent,
  terms: number,
  growthRate: number,
  targetRate: number
) {
  const arr: ReturnType<typeof calculateMidTerm>[] = []
  let i = 0
  for (i; i < terms; i++) {
    const term = calculateMidTerm(headlineRent, i + 2, growthRate, targetRate);
    arr.push(term)
  }
  return arr
}

function calculateMidTerm(
  headlineRent: HeadlineRent,
  currentTerm: number,
  growthRate: number,
  targetRate: number
) {
  const revision = calculateRevisionToFutureValue(growthRate, headlineRent.rentReviewCycle, currentTerm);
  const yp = calculateYearsPurchaseAtPercentRate(targetRate, headlineRent.rentReviewCycle);
  const year = headlineRent.rentReviewCycle * (currentTerm - 1);
  const pv = calculatePresentValueAtPercentRate(targetRate, year);
  const factor = revision * yp * pv;
  const [termSuffix, prevTermSuffix] = getSuffixes(currentTerm);
  return {
    year,
    termSuffix,
    prevTermSuffix,
    revisionToFutureRentalValue: revision,
    presentValueOfRentReviewCycle: pv,
    yearsPurchaseOfRentReview: yp,
    factorValueOfRevisionaryRent: factor,
  }
}

function calculateRevisionToFutureValue(rate: number, year: number, term: number): number {
  if (rate === null || rate === 0) {
    return NaN;
  }
  const rateVal = rate / 100;
  let temp = year * (term - 1);
  return Math.pow((1 + rateVal), temp);
}

function calculateEffectiveRent(preER: number, growthRate: number, reviewCycle: number): number {
  if (growthRate === null) {
    return NaN;
  }
  const rateVal = growthRate / 100;
  let temp = Math.pow((1 + rateVal), reviewCycle);
  return preER * temp;
}

function getSuffixes(term: number): [string, string] {
  const r = term % 10;
  switch (r) {
    case 1:
      return ['st', 'th'];
    case 2:
      return ['nd', 'st'];
    case 3:
      return ['rd', 'nd'];
    case 4:
      return ['th', 'rd'];
    default:
      return ['th', 'th'];
  }
}

function roundCFMResult(val: CashFlowMethodData): CashFlowMethodData {
  const preliminary = Object.assign({}, val.preliminary) as CFMPreliminary;
  const firstTerm = Object.assign({}, val.firstTerm) as CFMFirstTerm;
  const lastTerm = Object.assign({}, val.lastTerm) as CFMLastTerm;
  const midTerms: CFMMidTerm[] = [];

  Object.entries(val.preliminary).forEach(([field, value]) => {
    preliminary[field] = roundTo4Decimals(value);
  });
  Object.entries(val.firstTerm).forEach(([field, value]) => {
    firstTerm[field] = roundTo4Decimals(value);
  })
  Object.entries(val.lastTerm).forEach(([field, value]) => {
    lastTerm[field] = typeof value === 'number' ? roundTo4Decimals(value) : value;
  });
  val.midTerms.forEach(mt => {
    const midTerm = Object.assign({}, mt) as CFMMidTerm;
    Object.entries(mt).forEach(([field, value]) => {
      midTerm[field] = typeof value === 'number' ? roundTo4Decimals(value) : value;
    });
    midTerms.push(midTerm);
  })

  let effectiveRent = roundTo4Decimals(val.results.effectiveRent);
  const effectiveRents: CFMEffectiveRent[] = [];
  val.results.effectiveRents.forEach(er => {
    const e = Object.assign({}, er) as CFMEffectiveRent;
    e.prev = roundTo4Decimals(er.prev);
    e.value = roundTo4Decimals(er.value);
    effectiveRents.push(e);
  })


  const res: CashFlowMethodData = {
    numberOfTerms: val.numberOfTerms,
    numberOfTermsReal: roundTo4Decimals(val.numberOfTermsReal),
    remainingYears: val.remainingYears,
    preliminary,
    firstTerm,
    lastTerm,
    midTerms,
    factorValue: roundTo4Decimals(val.factorValue),
    results: {
      effectiveRent,
      effectiveRents,
      isSelected: false,
    }
  }
  return res;
}


function convertNanToUndefinedCFM(val: CashFlowMethodData): CashFlowMethodData {
  const preliminary = Object.assign({}, val.preliminary) as CFMPreliminary;
  const firstTerm = Object.assign({}, val.firstTerm) as CFMFirstTerm;
  const lastTerm = Object.assign({}, val.lastTerm) as CFMLastTerm;
  const midTerms: CFMMidTerm[] = [];

  Object.entries(val.preliminary).forEach(([field, value]) => {
    preliminary[field] = Number.isNaN(value) ? undefined : value;
  });
  Object.entries(val.firstTerm).forEach(([field, value]) => {
    firstTerm[field] = Number.isNaN(value) ? undefined : value;
  })
  Object.entries(val.lastTerm).forEach(([field, value]) => {
    lastTerm[field] = typeof value === 'number'
      ? Number.isNaN(value) ? undefined : value
      : value;
  });
  val.midTerms.forEach(mt => {
    const midTerm = Object.assign({}, mt) as CFMMidTerm;
    Object.entries(mt).forEach(([field, value]) => {
      midTerm[field] = typeof value === 'number'
        ? Number.isNaN(value) ? undefined : value
        : value;
    });
    midTerms.push(midTerm);
  })

  let effectiveRent = Number.isNaN(val.results.effectiveRent) ? undefined : val.results.effectiveRent;
  const effectiveRents: CFMEffectiveRent[] = [];
  val.results.effectiveRents.forEach(er => {
    const e = Object.assign({}, er) as CFMEffectiveRent;
    e.prev = Number.isNaN(er.prev) ? undefined : er.prev;
    e.value = Number.isNaN(er.value) ? undefined : er.value;
    effectiveRents.push(e);
  })


  const res: CashFlowMethodData = {
    numberOfTerms: val.numberOfTerms,
    numberOfTermsReal: val.numberOfTermsReal,
    remainingYears: val.remainingYears,
    preliminary,
    firstTerm,
    lastTerm,
    midTerms,
    factorValue: Number.isNaN(val.factorValue) ? undefined : val.factorValue,
    results: {
      effectiveRent,
      effectiveRents,
      isSelected: false,
    }
  }
  return res;
}