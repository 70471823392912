import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatTableDataSource} from '@angular/material/table';
import {LayoutUtilsService, TypesUtilsService} from '../../../../../core/_base/crud';
import {AppState} from '../../../../../core/reducers';
import {TranslateService} from '@ngx-translate/core';
import {
    AssetClassModel, selectTpFilesByTpId, TpFileOnServerCreated,
    TpFileOnServerUpdated,
    TpFileDeleted, TpTaskModel, selectTpTasksByTpId, TpTaskUpdated,
    AssetClassSourceExternalReferenceModel, TpTaskOnServerCreated, TpTaskDeleted,
    selectAssetClassSERByTpId, AssetClassSEROnServerUpdated, selectTpTasksPageLoading, AllPropertyDetailReportsRequested, TpTaskService
} from '../../../../../core/asset_class';
import {ActivatedRoute, Router} from '@angular/router';
import {UploadFileBehaviorComponent} from '../upload-file-behavior/upload-file-behavior.component';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {debounceTime, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {Update} from '@ngrx/entity';
import {TpFileModel} from '../../../../../core/asset_class';
import {ToeService} from '../../../../../core/toe';
import {AllApproachesToValuesRequested, AllMethodsToValuesRequested, MethodsToValue, ReportDeliveryMethod, selectAllApproachesToValues, selectAllMethodsToValues, selectAllReportDeliveryMethods} from '../../../../../core/linked-tables';
import {Globals} from '../../../../../core/_base/crud/utils/globals.service';
import {currentUser, User} from 'src/app/core/mad-auth/mad-auth.store';
import {AssignmentModel} from '../../../../../core/assignment';
import {PmEditDialogComponent} from '../pm-edit-dialog/pm-edit-dialog.component';
import {selectTpAdditionalFilesByTpId} from '../../../../../core/asset_class/_selectors/tp-file.selectors';
import { TpDocumentsComponent } from '../tp-documents/tp-documents.component';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { AllValuationReportsRequested } from 'src/app/core/asset_class/_actions/valuation-report.actions';
import { AllDraftStatementReportsRequested } from 'src/app/core/asset_class/_actions/draft-statement-report.action';
import { ManualModalComponent, ManualModalData } from './_sub/manual-modal/manual-modal.component';
import { AddValuationTaskScenario, DeleteValuationTaskScenario, DueDiligenceTpTaskOnServerUpdated, ManualTpTaskOnServerUpdated, UpdateMethodOfValuationTask } from 'src/app/core/asset_class/_actions/tp-task.actions';
import { DeliveryTaskModalComponent, DeliveryTaskModalData } from './_sub/delivery-task-modal/delivery-task-modal.component';
import { selectValuationReportsByTpId } from 'src/app/core/asset_class/_selectors/valuation-report.selectors';
import { selectDraftStatementReportByTpId } from 'src/app/core/asset_class/_selectors/draft-statement-report.selector';
import { TpTaskOrder } from 'src/app/core/asset_class/_models/tp-task.model';
import { AuditTaskModalComponent } from './_sub/audit-task-modal/audit-task-modal.component';
import { DueDiligenceModalComponent } from './_sub/due-diligence-modal/due-diligence-modal.component';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { ToeAuditTaskRemindersRequested } from 'src/app/core/toe/_actions/toe-audit-task-reminder.actions';
import { Tasks, DocumentTypes, mapTpTaskToTaskModel } from '../../state/task.model';
import * as _ from 'lodash';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { toeRole } from '../../../mad_shared/utils/toe-role';

enum ApproachToValue {
    Market = 4,
    Income = 2
}

enum MetodToValue {
    ComparableTransaction = 2
}

@Component({
    selector: 'kt-tp-row',
    templateUrl: './tp-row.component.html',
    styleUrls: ['./tp-row.component.scss']
})
export class TpRowComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(TpDocumentsComponent, {static: false})
    public uploadFileComponent: TpDocumentsComponent;

    statusData = ['Pending', 'Ongoing', 'Completed'];
    @Input() tpAssetClass: AssetClassModel;
    @Input() toeStatus: number;
    @Input() toeFinalDate: string;
    @Input() id: number;
    @Input() projectManager: boolean;
    @Input() leadValuerId: number;
    @Input() workers: User[];
    @Input() assignment: AssignmentModel;
    @Input() selectedRdm: number[] = [];
    @Input() pm: User;
    @Input() toeRestrictionsOnUse: number;
    @Input() toeId: number;

    loading$: Observable<boolean>;

    attFiles = new BehaviorSubject<TpFileModel[]>([]);
    additionalFiles = new BehaviorSubject<TpFileModel[]>([]);
    tasks: TpTaskModel[] = [];
    displayedColumns = ['task', 'details', 'date', 'user', 'actions'];
    dataSource = new MatTableDataSource([]);
    filteredCategory = [];
    checkedCategories = new BehaviorSubject<AssetClassSourceExternalReferenceModel[]>([]);

    currentUser: User;
    reachPermission = 0;
    tooltipDesc = 'Asset class\t\n' +
        'Property type\t\n' +
        'Tenure\t\n' +
        'Address\t\n' +
        'Surface area\t\n' +
        'Measurement standard\t\n' +
        'Premise of value\t\n' +
        'Approaches to value\t\n' +
        'Methods to value\t\n';


    reportDeliveryMethods: ReportDeliveryMethod[] = [];

    private subscriptions: Subscription[] = [];
    uploadFileComponentSub: Subscription;

    selectedTab = 0;

    decisions = [
        {label: 'Desktop valuation Details', value: 1, default: true},
        {label: 'External Inspection', value: 2, default: false},
        {label: 'Internal inspection', value: 3, default: false},
        {label: 'Full property survey', value: 4, default: false}
    ];

    taskTypes = [
        'Documents upload',
        'Property details',
        'Valuation',
        'Reporting',
        'Delivery',
    ];

    tpPercentage = 0;

    valuationCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    inspectionCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    draftStatementReportGenerated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    draftStatementReportGeneratedObs$: Observable<boolean>;
    valuationReportGenerated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    valuationReportGeneratedObs$: Observable<boolean>;

    signedDocUploadTaskUserId: number = undefined;

    canGenerateValuationReportSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    canGenerateValuationReport$: Observable<boolean> = this.canGenerateValuationReportSubject.asObservable();
    canGenerateDraftReportSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    canGenerateDraftReport$: Observable<boolean> = this.canGenerateDraftReportSubject.asObservable();
    propertyTypes = PropertyTypes;
    todo_ids: number[] = [];
    upcoming_ids: number[] = [];
    hasDocumentTasks: boolean = false;
    hasDocuments$: Observable<number>; 
    methodsToValueForIncome: any[] = []

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private layoutUtilsService: LayoutUtilsService,
                private toeService: ToeService,
                private global: Globals,
                private store: Store<AppState>,
                public dialog: MatDialog,
                private translate: TranslateService,
                private readonlyService: ReadonlyService,
                private tpTaskService: TpTaskService,
                public typesUtilsService: TypesUtilsService,
                public subDomainService: SubdomainService) {
        this.draftStatementReportGeneratedObs$ = this.draftStatementReportGenerated$.asObservable();
        this.valuationReportGeneratedObs$ = this.valuationReportGenerated$.asObservable();
    }

    ngOnInit() {
        // get tp details
        // get tp files
        // dispatch
        this.store.dispatch(new AllApproachesToValuesRequested())
        this.store.dispatch(new AllPropertyDetailReportsRequested({tpID: this.tpAssetClass.id}));
        this.store.dispatch(new AllValuationReportsRequested({tpID: this.tpAssetClass.id}));
        if (this.toeRestrictionsOnUse == 1) {
            // get draft statement reports
            this.store.dispatch(new AllDraftStatementReportsRequested({tpID: this.tpAssetClass.id}));
        }
        this.tpTaskService.getTpTodos().subscribe(res => {
            this.todo_ids = res.todo_ids;
            this.upcoming_ids = res.upcoming_ids;
        });
        this.hasDocuments$ = this.attFiles.asObservable().pipe(map(f => f.length));

        const methodsToValueSub = this.store.pipe(
            select(selectAllApproachesToValues),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            const incomeApproach = res.find(item => item.id == 2)
            if (!incomeApproach) {
                return;
            }
            this.methodsToValueForIncome = incomeApproach.methods_to_value.map(mtv => {
                // For testing
                const disabled = [8, 10, 13, 15, 16, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 33].includes(mtv.id);
                // const disabled = [7, 8, 10, 13, 15, 16, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 33].includes(mtv.id);
                return {
                    ...mtv,
                    disabled,
                    text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
                }
            })
        })
        this.subscriptions.push(methodsToValueSub)
        /*
        * */
        // WARNING: We're not using it.
        // this.tooltipDesc = '' + this.tpAssetClass.type_name + '<br>' +
        //     '' + this.tpAssetClass.property_type_name + '<br>' +
        //     '' + this.tpAssetClass.type_name + '<br>' + // tenure
        //     '' + this.tpAssetClass.address + '<br>' +
        //     '' + this.tpAssetClass.surface + '<br>' +
        //     '' + this.tpAssetClass.standard_measurement_name + '<br>' +
        //     '' + this.tpAssetClass.premise_of_value_name + '<br>' +
        //     '' + this.tpAssetClass.type_name + '<br>' + // Approaches to value
        //     '' + this.tpAssetClass.type_name + '<br>'; //  Methods to value

        // Tenure
        // Address
        // Surface area
        // Measurement standard
        // Premise of value
        // Approaches to value
        // Methods to value
        this.loading$ = this.store.pipe(select(selectTpTasksPageLoading));
        const checkedCategoriesSubscription = this.store.pipe(
            select(selectAssetClassSERByTpId(this.tpAssetClass.id))
        ).subscribe(
            res => {
                if (res) {
                    this.checkedCategories.next(res);
                }
            }
        );
        this.subscriptions.push(checkedCategoriesSubscription);


        const reportSubscription = this.store.pipe(
            select(selectAllReportDeliveryMethods)
        )
            .subscribe(res => {
                this.reportDeliveryMethods = [];
                if (res) {
                    this.reportDeliveryMethods = res;
                }
            });
        this.subscriptions.push(reportSubscription);

        const tpFilesByTpIdSubscription = this.store.pipe(select(selectTpFilesByTpId(this.tpAssetClass.id)))
            .subscribe(res => {
                if (res) {
                    this.attFiles.next(res);
                }
            });
        this.subscriptions.push(tpFilesByTpIdSubscription);

        const tpAdditionalFilesByTpIdSubscription = this.store.pipe(select(selectTpAdditionalFilesByTpId(this.tpAssetClass.id)))
            .subscribe(res => {
                if (res) {
                    this.additionalFiles.next(res);
                }
            });
        this.subscriptions.push(tpAdditionalFilesByTpIdSubscription);

        const reportsSub = combineLatest([
            this.store.select(selectValuationReportsByTpId(this.tpAssetClass.id)),
            this.store.select(selectDraftStatementReportByTpId(this.tpAssetClass.id))
        ]).subscribe(([valReports, draftReports]) => {
            if (this.tpAssetClass.top_property_type_id == -1) {
                this.valuationReportGenerated$.next(true);
            } else {
                if(valReports && valReports.length > 0) {
                    this.valuationReportGenerated$.next(true);
                } else {
                    this.valuationReportGenerated$.next(false);
                }
                if(draftReports && draftReports.length > 0) {
                    this.draftStatementReportGenerated$.next(true);
                } else {
                    this.draftStatementReportGenerated$.next(false);
                }
            }
        });
        this.subscriptions.push(reportsSub);

        const tpTasksByTpIdSubscriptionV2 = 
            combineLatest([
                this.store.pipe(select(selectTpTasksByTpId(this.tpAssetClass.id))),
                this.store.pipe(select(currentUser), tap(res => {
                    if (res) {
                        this.currentUser = res;
                    }
                })),
                this.draftStatementReportGenerated$,
                this.valuationReportGenerated$
            ])
            .subscribe(([tasks, currentUser, draftStatementReportGenerated, valReportGenerated]) => {
                if (tasks && currentUser) {
                    this.tasks = tasks;
                    let taskCompletionPercentage: Map<string, boolean[]> = new Map([
                        ["document", []],
                        ['logistic', []],
                        ['landmark', []],
                        ['investigation', []],
                        ['valuation', []],
                        ['reporting', []],
                        ['report-design', []],
                        ['signed_doc', []],
                        ['delivery', []],
                        ['due-diligence', []]
                    ])
                    if (tasks.find(el => el.task_id == Tasks.AuditTrailTask)) {
                        taskCompletionPercentage.set('audit_trail', [])
                    }
                    tasks.forEach(task => {
                        taskCompletionPercentage = setTaskCompletion(taskCompletionPercentage, task.task_id, task.point == task.max_point)
                        if (task.task_id == Tasks.InvestigationTask) {
                            this.inspectionCompleted$.next(task.point == task.max_point)
                        }

                        if (task.task_id == Tasks.ValuationTask) {
                            this.valuationCompleted$.next(task.point == task.max_point)
                        }
                    })
                    this._reportGenerationStatus(taskCompletionPercentage)

                    const dataSource: {
                        task: any;
                        canReach: boolean;
                        canBeShown: boolean;
                        otherTasks?: any[];
                        tooltip?: string;
                        lastInCategory?: boolean;
                        assignee?: {
                            firstName: string;
                        },
                    }[] = []
                    const refTasks = tasks.filter(t => t.task_id == Tasks.ExternalRefTask);
                    const otherTasks = tasks.filter(t => t.task_id != Tasks.ExternalRefTask && t.task_id != Tasks.ValuationTask);
                    const valuationTasks = moveLiquidationValuations(
                        tasks.filter(t => t.task_id === Tasks.ValuationTask)
                    )
                    const uploadTasks = tasks.filter(t => t.task_id == Tasks.UploadDocumentTask);
                    uploadTasks.sort((a, b) => b.second_id - a.second_id)

                    const documentTask = this._groupTasks(refTasks, 'ref_doc', currentUser, this.leadValuerId);
                    if (documentTask) {
                        dataSource.push({
                            task: documentTask.task,
                            canReach: documentTask.userOfTask,
                            canBeShown: documentTask.canBeShown,
                        });
                        this.hasDocumentTasks = true;
                    }
                    const _valuationTasks = this.convertValuationTasksForDisplay(valuationTasks, currentUser, tasks);
                    _valuationTasks.forEach(t => dataSource.push(t))
                    otherTasks.map(t => {
                        const userOfTask = currentUser.id == t.user_id || currentUser.id == this.leadValuerId || currentUser.id == this.pm.id 
                        if (t.task_id == Tasks.LogisticsTask) {
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Investigation',
                                    details: 'Logistics',
                                    order: 1
                                },
                                canReach: userOfTask,
                                canBeShown: t.max_point === t.point && currentUser.id != t.user_id
                            })
                            return;
                        }
                        if (t.task_id == Tasks.LandmarksTask) {
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Investigation',
                                    details: 'Landmarks',
                                    order: 2
                                },
                                canReach: userOfTask,
                                canBeShown: t.max_point == t.point && currentUser.id != t.user_id
                            })
                            return;
                        }
                        if (t.task_id == Tasks.InvestigationTask) {
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Investigation',
                                    order: 3
                                },
                                canReach: userOfTask && canReachInvestigationTask(tasks),
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                                lastInCategory: true
                            })
                            return;
                        }
                        if (t.task_id == Tasks.ReportDesignTask) {
                            const canReach = canReachReporting(taskCompletionPercentage)
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: `Reporting`,
                                    details: 'Design',
                                    order: 5,
                                },
                                canReach: userOfTask && canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                            })
                            return;
                        }
                        if (t.task_id == Tasks.ReportingTask) {
                            const canReach = canReachReporting(taskCompletionPercentage)
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Reporting',
                                    details: 'Valuation',
                                    order: 6
                                },
                                canReach: userOfTask && canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                                lastInCategory: true
                            })
                            return;
                        }

                        if (t.task_id == Tasks.AuditTrailTask) {
                            const canReach = userOfTask
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Audit Trail',
                                    details: t.details,
                                    order: 7,
                                },
                                canReach: canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                                lastInCategory: true
                            })
                            return;
                        }

                        if (t.task_id == Tasks.UploadDocumentTask && t.second_id == DocumentTypes.Valuation) {
                            const index = uploadTasks.findIndex(ut => ut.id == t.id)
                            const canReach = canReachSigned(taskCompletionPercentage)
                            this.signedDocUploadTaskUserId = t.user_id;
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Upload',
                                    details: 'Signed Valuation Report',
                                    order: 8
                                },
                                canReach: userOfTask && canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                                lastInCategory: (index + 1) == uploadTasks.length
                            })
                            return;
                        }

                        if (t.task_id == Tasks.UploadDocumentTask && t.second_id == DocumentTypes.DraftStatement) {
                            const index = uploadTasks.findIndex(ut => ut.id == t.id)
                            const canReach = canReachSigned(taskCompletionPercentage)
                            this.signedDocUploadTaskUserId = t.user_id;
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Upload',
                                    details: 'Signed Draft Statement',
                                    order: 9
                                },
                                canReach: userOfTask && canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                                lastInCategory: (index + 1) == uploadTasks.length
                            })
                            return;
                        }
                        if (t.task_id == Tasks.DeliveryTask && t.third_id == DocumentTypes.Valuation) {
                            const canReach = canReachDeliveryTask(tasks, Tasks.UploadDocumentTask, DocumentTypes.Valuation)
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Client Hand-Off',
                                    details: `${t.details} - Valuation Report`,
                                    order: 10,
                                    order_num: TpTaskOrder.DeliveryVal
                                },
                                canReach: userOfTask && canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                            })
                            return;
                        }
                        if (t.task_id == Tasks.DeliveryTask && t.third_id == DocumentTypes.DraftStatement) {
                            const canReach = canReachDeliveryTask(tasks, Tasks.UploadDocumentTask, DocumentTypes.DraftStatement)
                            dataSource.push({
                                task: {
                                    ...t,
                                    task_name: 'Client Hand-Off',
                                    details: `${t.details} - Draft Statement`,
                                    order: 11,
                                    order_num: TpTaskOrder.DeliveryDraft
                                },
                                canReach: userOfTask && canReach,
                                canBeShown: t.point == t.max_point && currentUser.id != t.user_id,
                            })
                            return;
                        }
                    })
                    dataSource.sort((a, b) => a.task.order - b.task.order)
                    // this.dataSource.data = dataSource.map(item => {
                    //     const group = item.task.task_name;
                    //     const itemsInGroup = dataSource.filter(item => item.task.task_name == group);
                    //     const isFirstItem = itemsInGroup.findIndex(_item => _item.task.id == item.task.id)
                    //     return {
                    //         ...item,
                    //         rowSpan: isFirstItem == 0 ? itemsInGroup.length : 0
                    //     };
                    // })
                    this.dataSource.data = this.groupTasks(dataSource).map(item => {
                        return {
                            ...item,
                            assignee: item.task.assignee ? {
                                firstName: item.task.assignee.first_name,
                                lastName: item.task.assignee.last_name,
                                picture: item.task.assignee.picture,
                                email: item.task.assignee.email,
                                qualification: item.task.assignee.qualification_name,
                                agency: this.assignment?.agency_name,
                                role: toeRole(item.task.assignee.id, this.pm?.id, this.leadValuerId, this.isWorker(item.task.assignee.id))
                            } : null
                        }
                    })
                    this.tpPercentage = computeTargetPropertyTaskPercentage(taskCompletionPercentage)
                }
            })
        this.subscriptions.push(tpTasksByTpIdSubscriptionV2)

        // const tpTasksByTpIdSubscription =
        //     combineLatest([
        //         this.store.pipe(select(selectTpTasksByTpId(this.tpAssetClass.id))),
        //         this.draftStatementReportGenerated$,
        //         this.valuationReportGenerated$,
        //         this.store.pipe(select(currentUser), tap(res => {
        //             if (res) {
        //                 this.currentUser = res;
        //             }
        //         }))
        //     ])
        //     .subscribe(([res, draftReportGenerated, ValReportGenerated, currentUser ]) => {
        //         if (res && currentUser) {
        //             let taskCompletionPercentage: Map<string, boolean[]> = new Map([
        //                 ["document", []],
        //                 ["investigation", []],
        //                 ["valuation", []],
        //                 ["reporting", []],
        //                 ["signed_doc", []],
        //                 ["delivery", []],
        //                 ["due-diligence", []],
        //             ]);
        //             if (res.find(el => el.task_id == 11)) {
        //                 taskCompletionPercentage.set("audit_trail", []);
        //             }
        //             let sumMax = 0;
        //             let sumPoint = 0;

        //             this.reachPermission = 0;
        //             let tasksLvl1 = 0;
        //             let completedTasksLvl1 = 0;
        //             res.forEach(el => {
        //                 taskCompletionPercentage = setTaskCompletion(taskCompletionPercentage, el.task_id, el.point == el.max_point);
        //                 sumMax += el.max_point;
        //                 sumPoint += el.point;

        //                 if (el.task_id < 2) {
        //                     tasksLvl1++;
        //                     if (el.point == el.max_point) {
        //                         completedTasksLvl1++;
        //                     }
        //                 }

        //                 if (el.task_id === 1) {
        //                     if (el.point == el.max_point) {
        //                         this.inspectionCompleted$.next(true);
        //                     } else {
        //                         this.inspectionCompleted$.next(false);
        //                     }
        //                 }
                        
        //                 if (el.task_id === 3) {
        //                     if (el.point == el.max_point) {
        //                         this.valuationCompleted$.next(true);
        //                     } else {
        //                         this.valuationCompleted$.next(false);
        //                     }
        //                 }
        //             });
        //             this._reportGenerationStatus(taskCompletionPercentage);

        //             this.tasks = res;
        //             const datasource: {
        //                 task: any,
        //                 canReach: boolean;
        //                 canBeShown: boolean;
        //                 otherTasks?: any[];
        //                 tooltip?: string;
        //             }[] = [];
        //             const documentTasks = this.tasks.filter(t => t.order_num == TpTaskOrder.DocumentUpload);
        //             const uploadTasks = this.tasks.filter(t => t.task_id == 10);
        //             const deliveryTasks = this.tasks.filter(t => t.task_id == 4);
        //             const dueDiligenceTask = this.tasks.find(t => t.task_id == 12);
        //             if (dueDiligenceTask) {
        //                 const userOfTask = currentUser.id == dueDiligenceTask.user_id || currentUser.id == this.leadValuerId || currentUser.id == this.pm.id;
        //                 const canBeShown = dueDiligenceTask.point == dueDiligenceTask.max_point && currentUser.id != dueDiligenceTask.user_id
        //                 const splitted = dueDiligenceTask.details.split('$');
        //                 datasource.push({
        //                     task: {...dueDiligenceTask, details: splitted.shift()},
        //                     canReach: userOfTask,
        //                     canBeShown: canBeShown,
        //                     tooltip: splitted[0],
        //                 })
        //             }
        //             const otherTasks = this.tasks.filter(t => t.order_num != TpTaskOrder.DocumentUpload && t.task_id != 10 && t.task_id != 4 && t.task_id != 12);
        //             const valuationTasks = otherTasks.filter(t => t.task_id === Tasks.ValuationTask)
        //             // Document Upload Task Simplified
        //             const documentTask = this._groupTasks(documentTasks, 'ref_doc', currentUser, this.leadValuerId);
        //             if (documentTask) {
        //                 datasource.push({
        //                     task: documentTask.task,
        //                     canReach: documentTask.userOfTask,
        //                     canBeShown: documentTask.canBeShown
        //                 });
        //                 this.hasDocumentTasks = true;
        //             }
        //             const uploadTask = this._groupTasks(uploadTasks, 'doc_up', currentUser, this.leadValuerId);
        //             if (uploadTask) {
        //                 this.signedDocUploadTaskUserId = uploadTask.task.user_id;
        //                 const canReach = uploadTask.userOfTask && canReachSigned(taskCompletionPercentage) && (draftReportGenerated || ValReportGenerated);
        //                 datasource.push({
        //                     task: uploadTask.task,
        //                     canReach,
        //                     canBeShown: uploadTask.canBeShown
        //                 });
        //             }
        //             const deliveryTask = this._groupTasks(deliveryTasks, 'delivery', currentUser, this.leadValuerId)
        //             if (deliveryTask) {
        //                 const canReach = deliveryTask.userOfTask && (canReachDelivery(this.tasks, TpTaskOrder.SignedDocDraft) || canReachDelivery(this.tasks, TpTaskOrder.SignedDocVal));
        //                 datasource.push({
        //                     task: deliveryTask.task,
        //                     canReach,
        //                     canBeShown: uploadTask.canBeShown,
        //                     otherTasks: deliveryTask.otherTasks
        //                 })
        //             }
        //             otherTasks.forEach(t => {
        //                 const userOfTask = currentUser.id == t.user_id || currentUser.id == this.leadValuerId || currentUser.id == this.pm.id;
        //                 let canReach = false;
        //                 if (t.order_num == TpTaskOrder.Investigation) {
        //                     canReach = userOfTask;
        //                 }
        //                 if (t.order_num == TpTaskOrder.Valuation) {
        //                     const index = valuationTasks.findIndex(vt => vt.id === t.id)
        //                     const _temp = canReachVal(taskCompletionPercentage);
        //                     canReach = userOfTask && _temp; 
        //                     datasource.push({
        //                         task: {
        //                             ...t,
        //                             task_name: `Valuation ${index + 1}`,
        //                         },
        //                         canReach: canReach,
        //                         canBeShown: t.point == t.max_point && currentUser.id != t.user_id
        //                     })
        //                     return;
        //                 }
        //                 if (t.order_num == TpTaskOrder.Reporting) {
        //                     const _temp = canReachReporting(taskCompletionPercentage);
        //                     canReach = userOfTask && _temp;
        //                 }
        //                 if (t.order_num == TpTaskOrder.SignedDocDraft) {
        //                     const _temp = canReachSigned(taskCompletionPercentage);
        //                     canReach = userOfTask && _temp && draftReportGenerated;
        //                 }
        //                 if (t.order_num == TpTaskOrder.DeliveryDraft) {
        //                     canReach = userOfTask && canReachDelivery(this.tasks, TpTaskOrder.SignedDocDraft);
        //                 }
        //                 if (t.order_num == TpTaskOrder.SignedDocVal) {
        //                     canReach = userOfTask && canReachSigned(taskCompletionPercentage) && ValReportGenerated;
        //                 }
        //                 if (t.order_num == TpTaskOrder.DeliveryVal) {
        //                     canReach = userOfTask && canReachDelivery(this.tasks, TpTaskOrder.SignedDocVal);
        //                 }
        //                 if (t.order_num == TpTaskOrder.AuditTrail) {
        //                     canReach = userOfTask;
        //                 }
        //                 if (t.task_id == Tasks.LogisticsTask) {
        //                     canReach = userOfTask
        //                     datasource.push({
        //                         task: {
        //                             ...t,
        //                             task_name: 'Investigation',
        //                             details: 'Logistics'
        //                         },
        //                         canReach: canReach,
        //                         canBeShown: t.point == t.max_point && currentUser.id != t.user_id
        //                     })
        //                     return;
        //                 }
        //                 if (t.task_id == Tasks.LandmarksTask) {
        //                     canReach = userOfTask
        //                     datasource.push({
        //                         task: {
        //                             ...t,
        //                             task_name: 'Investigation',
        //                             details: 'Landmarks'
        //                         },
        //                         canReach: canReach,
        //                         canBeShown: t.point == t.max_point && currentUser.id != t.user_id
        //                     })
        //                     return;
        //                 }
        //                 if (t.task_id == Tasks.ReportDesignTask) {
        //                     datasource.push({
        //                         task: {
        //                             ...t,
        //                             task_name: 'Reporting',
        //                             details: 'Design'
        //                         },
        //                         canReach: true,
        //                         canBeShown: false
        //                     })
        //                     return;
        //                 }
        //                 datasource.push({
        //                     task: t,
        //                     canReach: canReach,
        //                     canBeShown: t.point == t.max_point && currentUser.id != t.user_id
        //                 })
        //             })
        //             // this.dataSource.data = datasource.sort((a, b) => a.task.order_num - b.task.order_num);
        //             // this.tpPercentage = computePercentage(taskCompletionPercentage);
        //         }
        //     });
        // this.subscriptions.push(tpTasksByTpIdSubscription);
    }

    ngAfterViewInit() {
        // navigate from my-tasks for documents
        if (sessionStorage.getItem('tab_index') != null) {
            this.selectedTab = Number(sessionStorage.getItem('tab_index'));
        }
    }

    ngOnDestroy(): void {
        if (this.uploadFileComponentSub) {
            this.uploadFileComponentSub.unsubscribe();
        }
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    selectedTabChange(event: MatTabChangeEvent) {
        if (event) {
            if (event.index == 1) {
                if (this.uploadFileComponentSub) {
                    this.uploadFileComponentSub.unsubscribe();
                }
                this.uploadFileComponentSub = this.uploadFileComponent 
                    ? this.uploadFileComponent.lastChangedItem
                        .asObservable()
                        .pipe(debounceTime(500))
                        .subscribe((res: TpFileModel) => {
                                if (!res) {
                                    return;
                                }

                                if (!res.cat_id) {
                                    return;
                                }

                                if (res.id) {                           // Check if TpFile is created
                                    if (res.client_ref === 1) {
                                        // Changing Ref_Available Yes to No
                                        this._deleteTaskRow(res);
                                        this._deleteTpFile(res);
                                        this._changeAssetClassSERRefAv(0, res);
                                        // this._updateAssetClass(res);
                                        return;
                                    } else if (res._isEditMode) {
                                        // Deleting Uploaded file
                                        this._deleteUploadFile(res);
                                    } else {
                                        // Uploading file
                                        this._updateTpFile(res);
                                    }
                                } else {                                // TpFile is not created
                                    this._createTpFile(res);
                                    const _task = this._getTask(res);
                                    if (!_task) {
                                        this._createTaskRow(res);
                                        this._changeAssetClassSERRefAv(1, res);
                                    } else {
                                        this._updateTaskRow(res);
                                    }
                                }
                            }
                        )
                    : of().subscribe();
            }
        }
    }

    _deleteTaskRow(item: TpFileModel) {
        const _task = this._getTask(item);
        if (_task) {
            this.store.dispatch(new TpTaskDeleted({
                id: _task.id
            }));
        }
    }

    _deleteTpFile(item: TpFileModel) {
        this.store.dispatch(new TpFileDeleted({
            id: item.id
        }));
    }

    _changeAssetClassSERRefAv(refAv: number, item: TpFileModel) {
        const cats = this.checkedCategories.getValue();
        const changedCats = Object.assign([], cats);
        const _cat = changedCats.find(val => val.source_external_reference_id == item.cat_id);
        const changedItem = Object.assign({}, _cat);
        changedItem.reference_available = refAv;
        changedItem.description = item.ac_ser ? item.ac_ser.description : _cat.description;
        this.store.dispatch(new AssetClassSEROnServerUpdated({
            ser: changedItem
        }));
        this.store.dispatch(new ToeAuditTaskRemindersRequested({toe_id: this.global.activeTOEId}))
    }

    _deleteUploadFile(item: TpFileModel) {
        let cnt = 0;
        if (this.attFiles && this.attFiles.value) {
            this.attFiles.value.forEach(el => {
                if (el.cat_id == item.cat_id) {
                    cnt++;
                }
            });
        }
        if (cnt < 2) {
            const _editedItem = Object.assign({}, item) as TpFileModel;
            _editedItem.path = '';
            _editedItem.name = '';
            _editedItem._isEditMode = false;
            this._updateTpFile(_editedItem);
        } else {
            this._deleteTpFile(item);
        }
    }

    _createTaskRow(item: TpFileModel) {
        if (this.tpAssetClass.id === this.global.activeTPId) {
            const newTask = new TpTaskModel();
            newTask.toe_id = this.tpAssetClass.toe_id;
            newTask.tp_id = this.tpAssetClass.id;
            newTask.task_id = 0;
            newTask.second_id = item.cat_id;
            this.store.dispatch(new TpTaskOnServerCreated({
                tp_id: this.tpAssetClass.id,
                task: newTask
            }));
        }
    }

    _createTpFile(item: TpFileModel) {
        if (this.tpAssetClass.id == this.global.activeTPId) {
            this.store.dispatch(new TpFileOnServerCreated({
                tp_id: this.tpAssetClass.id,
                tp_file: item
            }));
        }
    }

    _updateTpFile(item: TpFileModel) {
        const partialItem: Update<TpFileModel> = {
            id: item.id,
            changes: item
        };
        this.store.dispatch(new TpFileOnServerUpdated({
            tp_id: this.tpAssetClass.id,
            partialTpFile: partialItem,
            tpFile: item
        }));

        this._updateTaskRow(item);
    }

    _updateTaskRow(item: TpFileModel) {
        const _task = this._getTask(item);
        const _point = item.client_ref == 1 || (item.client_ref == 2 && item.name.length > 0) ? _task.max_point : 0;
        if (_task.point != _point) {
            const changedItem = Object.assign({}, _task);
            changedItem.point = _point;
            const updateItem: Update<TpTaskModel> = {
                id: changedItem.id,
                changes: changedItem
            };
            this.store.dispatch(new TpTaskUpdated({
                partialItem: updateItem,
                item: changedItem
            }));
        }
    }

    _getTask(item: TpFileModel): TpTaskModel {
        return this.tasks.find(el => el.task_id == 0 && el.second_id == item.cat_id);
    }

    showRow(item) {
        return;
        if (!item.is_manual) {
            switch (item.task_id) {
                case  0:
                    this.selectedTab = 1;
                    break;
                case  1:
                    this.readonlyService.setReadOnly(true);
                    if (item.tp_meta) {
                        switch (item.tp_meta.property_sub_type_id) {
                            case 1:
                            case 3:
                            case 2:
                            case 5:
                            case 7:
                            case 11:
                            case 17:
                                this.router.navigate(['../inspection-base', item.tp_id], {relativeTo: this.activatedRoute});
                                break;
                            default:
                                this.router.navigate(['../inspection', item.tp_id], {relativeTo: this.activatedRoute});
                        }
                    } else {
                        this.router.navigate(['../inspection', item.tp_id], {relativeTo: this.activatedRoute});
                    }
                    break;
                case  2:
                    this.readonlyService.setReadOnly(true);
                    if (item.tp_meta && (item.tp_meta.method_to_value_id == 2)) {
                        this.router.navigate(['../v2/valuation-process', item.tp_id, item.second_id], {relativeTo: this.activatedRoute});
                        return;
                    } else {
                        this._openManualModal(item);
                        return;
                    }
                    // this.router.navigate(['../valuation-process', item.tp_id, item.second_id], {relativeTo: this.activatedRoute});
                    break;
                case  3:
                    this.readonlyService.setReadOnly(true);
                    const tasks = this.dataSource.data
                        .find(item => item.task.task_id == 2)
                    if (tasks && tasks.task.tp_meta && tasks.task.tp_meta.method_to_value_id == 2) {
                        this.router.navigate(['../reporting-process', item.tp_id], {relativeTo: this.activatedRoute});
                    } else {
                        this._openManualModal(item);
                    }
                    // this.router.navigate(['../reporting-process', item.tp_id], {relativeTo: this.activatedRoute});
                    break;
                case 4:
                    this.readonlyService.setReadOnly(true);
                    this._openDeliveryTaskModal(item);
                    break;
                case 10: 
                    this.readonlyService.setReadOnly(true);
                    this.selectedTab = 3;
                    break;
                case 12:
                    this.readonlyService.setReadOnly(true);
                    this._openDueDiligenceModal(item)
                    break;
                case Tasks.LogisticsTask:
                    this.readonlyService.setReadOnly(true);
                    this.router.navigate(['../logistics', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                case Tasks.LandmarksTask:
                    this.readonlyService.setReadOnly(true);
                    this.router.navigate(['../landmarks', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                case Tasks.ReportDesignTask:
                    this.readonlyService.setReadOnly(true);
                    this.router.navigate(['../report-design', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                default:
                    break;
            }
        } else {
            switch (item.task_id) {
                case  0:
                    this.selectedTab = 1;
                    break;
                case 1:
                case 2:
                case 3:
                case Tasks.LogisticsTask:
                case Tasks.ReportDesignTask:
                    this.readonlyService.setReadOnly(true);
                    this._openManualModal(item);
                    break;
                case Tasks.LandmarksTask:
                    this.readonlyService.setReadOnly(true);
                    this.router.navigate(['../landmarks', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                case  4:
                    this.readonlyService.setReadOnly(true);
                    this._openDeliveryTaskModal(item);
                    break;
                case 10:
                    this.selectedTab = 3;
                    break;
                case 12:
                    this.readonlyService.setReadOnly(true);
                    this._openDueDiligenceModal(item)
                    break;
                default:
                    break;

            }
        }

    }


    editRow(item, row) {
        if (!item.is_manual) {
            switch (item.task_id) {
                case  0:
                    this.selectedTab = 1;
                    break;
                case  1:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    if (item.tp_meta) {
                        switch (item.tp_meta.property_sub_type_id) {
                            case 1:
                            case 3:
                            case 2:
                            case 5:
                            case 7:
                            case 11:
                            case 17:
                                this.router.navigate(['../inspection-base', item.tp_id], {relativeTo: this.activatedRoute});
                                break;
                            default:
                                this.router.navigate(['../inspection', item.tp_id], {relativeTo: this.activatedRoute});
                        }
                    } else {
                        this.router.navigate(['../inspection', item.tp_id], {relativeTo: this.activatedRoute});
                    }
                    break;
                case  2:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    if (item.tp_meta && ((item.tp_meta.method_to_value_id == 2) 
                        // // For Testing
                        // || (item.tp_meta.approach_to_value_id == 2)
                        || (item.tp_meta.method_to_value_id == 9)
                        )) {
                        this.router.navigate(['../v2/valuation-process', item.tp_id, item.second_id], {relativeTo: this.activatedRoute});
                    } else if (item.tp_meta && (item.tp_meta.approach_to_value_id == 2) && (item.tp_meta.method_to_value_id == 16)) {
                        this.router.navigate(['../v2/valuation-process-new', item.tp_id, item.second_id], {relativeTo: this.activatedRoute})
                    } else {
                        this._openManualModal(item);
                    }
                    break;
                case  3:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false)
                    const tasks = this.dataSource.data
                        .find(item => item.task.task_id == 2)
                    if (tasks && tasks.task.tp_meta && (tasks.task.tp_meta.method_to_value_id == 2
                        || (tasks.task.tp_meta.approach_to_value_id == 2)
                        || (tasks.task.tp_meta.method_to_value_id == 9)
                        )) {
                        this.router.navigate(['../reporting-process', item.tp_id], {relativeTo: this.activatedRoute});
                    } else {
                        this._openManualModal(item);
                    }
                    break;
                case 4:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openDeliveryTaskModal([row.task]);
                    break;
                case 10:
                    this.selectedTab = 3;
                    break;
                case 11:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openAuditTrailTaskModal(item);
                    break;
                case 12:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openDueDiligenceModal(item)
                    break;
                case Tasks.LogisticsTask:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this.router.navigate(['../logistics', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                case Tasks.LandmarksTask:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this.router.navigate(['../landmarks', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                case Tasks.ReportDesignTask:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this.router.navigate(['../report-design', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                default:
                    break;
            }
        } else {
            switch (item.task_id) {
                case  0:
                    this.selectedTab = 1;
                    break;
                case 1:
                case 2:
                case 3:
                case Tasks.LogisticsTask:
                case Tasks.ReportDesignTask:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openManualModal(item);
                    break;
                case Tasks.LandmarksTask:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this.router.navigate(['../landmarks', item.tp_id], {relativeTo: this.activatedRoute})
                    break;
                case 4:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openDeliveryTaskModal([row.task]);
                    break;
                case 10:
                    this.selectedTab = 3;
                    break;
                case 11:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openAuditTrailTaskModal(item);
                    break;
                case 12:
                    this.readonlyService.setReadOnly(this.toeStatus == 4 ? true : false);
                    this._openDueDiligenceModal(item)
                    break;
                default:
                    break;

            }
        }
    }

    private _openManualModal(item: TpTaskModel) {
        const dialogRef = this.dialog.open<ManualModalComponent, ManualModalData, ManualModalData>(ManualModalComponent, {
            data: {
                completed: item.point == item.max_point,
                completed_date: item.completed_at ? new Date(item.completed_at) : null
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.store.dispatch(new ManualTpTaskOnServerUpdated({
                id: item.id,
                completed: res.completed,
                complated_at: res.completed_date
            }))
        })
    }

    private _openDueDiligenceModal(item: TpTaskModel)
    {
        const dialogRef = this.dialog.open(DueDiligenceModalComponent, {
            data: {
                completed: item.point == item.max_point,
                completed_date: item.completed_at ? new Date(item.completed_at) : null
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.store.dispatch(new DueDiligenceTpTaskOnServerUpdated({
                id: item.id,
                completed: res.completed,
                completed_at: res.completed_date
            }))
        })
    }

    edit(obj) {

        const _title = 'Change Information';
        let _users = Object.assign([], this.workers);

        switch (obj.task_id) {
            case 0:             // Documents upload task
                _users = this.documentUploadTaskCandidates(_users);
                break;
            case 1:             // Property Details
                _users = this.propertyDetailsTaskCandidates(_users);
                break;
            case 2:             // Valuation Task
                _users = this.valuationTaskCandidates(_users);
                break;
            case 3:             // Reporting Task
                _users = this.reportingTaskCandidates(_users);
                break;
            case 4:             // Delivery Task
                _users = this.deliveryTaksCandidates(_users);
                break;
        }

        this.dialog.open(PmEditDialogComponent, {
            data: {
                title: _title,
                item: obj,
                users: _users,
                manager_id: this.assignment.valuer_id,
                lead_valuer_id: this.leadValuerId,
                default_date: this.toeFinalDate,
                pmIsWorker: this.isWorker(this.pm.id)
            },
            width: '440px',
        });
    }

    private documentUploadTaskCandidates(_users: User[]): User[] {
        const _candidates = Object.assign([], _users) as User[];
        return _candidates;
    }

    private propertyDetailsTaskCandidates(_users: User[]): User[] {
        const _candidates = Object.assign([], _users) as User[];
        return _candidates;
    }

    private valuationTaskCandidates(_users: User[]): User[] {
        const _candidates = Object.assign([], _users) as User[];
        return _candidates;
    }

    private reportingTaskCandidates(_users: User[]): User[] {
        const _candidates = _users.filter(u => u.id === this.leadValuerId);
        return _candidates;
    }

    private deliveryTaksCandidates(_users: User[]): User[] {
        const _candidates = Object.assign([], _users) as User[];
        return _candidates;
    }

    private isWorker(uId: number) {
        const worker = this.workers.filter(w => w.id === uId);
        return worker.length > 0;
    }

    public getStatus(date: string, completed: boolean, state): {
        status: number,
        text: string
    } {
        if (state == 1) {
            return {
                status: 4, 
                text: 'Aborted'
            }
        }
        const currentDate = new Date();
        const expDate = this.typesUtilsService.getDateFromString(date);
        let status = undefined;
        let text = null;
        if (this.isDatesAreSame(currentDate, expDate)) {
            status = 1;
        } else {
            const diff = currentDate.getTime() - expDate.getTime();
            if (diff < 0) {
                status = 0;
                text = this.getDaysString(Math.abs(diff));
            } else {
                status = 2;
                text = this.getDaysString(diff);
            }
        }

        if (completed) {
            status = 3;
        }

        return {
            status,
            text
        }
    }
    private isDatesAreSame(a: Date, b: Date): boolean {
        return (
        a.getFullYear() == b.getFullYear() && a.getMonth() == b.getMonth() && a.getDate() == b.getDate()
        )
    }

    private getDaysString(diff: number): string {
        const days = Math.floor(diff / 1000 / 60 / 60 / 24);
        if (days == 0) {
            return '1 day'
        }
        return `${days} day${days > 1 ? 's' : ''}`
    }

    private _openDeliveryTaskModal(items: TpTaskModel[]) {
        const draftTasks = items.filter(item => item.order_num == TpTaskOrder.DeliveryDraft);
        const DraftTasks = draftTasks.map(task => {
            const taskId = task.id
            const taskCanReach = taskId ? canReachDeliveryTask(this.tasks, Tasks.UploadDocumentTask, DocumentTypes.DraftStatement) : false;
            return {id: taskId, doable: taskCanReach, details: task.details};
        })
        const valTasks = items.filter(item => item.order_num == TpTaskOrder.DeliveryVal);
        const ValTasks = valTasks.map(task => {
            const taskId = task.id
            const taskCanReach = taskId ? canReachDeliveryTask(this.tasks, Tasks.UploadDocumentTask, DocumentTypes.Valuation) : false;
            return {id: taskId, doable: taskCanReach, details: task.details};
        })

        const dialogRef = this.dialog.open<DeliveryTaskModalComponent, DeliveryTaskModalData>(DeliveryTaskModalComponent, {
            data: {
                draftTasks: DraftTasks,
                valTasks: ValTasks
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            // Update tasks
            res.result.forEach(r => {
                const task = items.find(item => item.id == r.task_id);
                if (task) {
                    const _newItem = Object.assign({}, task) as TpTaskModel;
                    _newItem.point = r.is_delivered ? task.max_point : 0;
                    const _partial: Update<TpTaskModel> = {
                        id: task.id,
                        changes: _newItem
                    };
                    this.store.dispatch(new TpTaskUpdated({
                        partialItem: _partial,
                        item: _newItem
                    }));
                }
            })

        });
    }

    private _openAuditTrailTaskModal(task: TpTaskModel) {
        const dialogRef = this.dialog.open(AuditTaskModalComponent, {
            data: {
                task_id: task.id
            },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            const _newItem = Object.assign({}, task) as TpTaskModel;
            _newItem.point = res.result.created == 1 ? task.max_point : 0;
            const _partial: Update<TpTaskModel> = {
                id: task.id,
                changes: _newItem 
            }
            this.store.dispatch(new TpTaskUpdated({
                partialItem: _partial,
                item: _newItem
            }))
        })
    }

    private _groupTasks(tasks: TpTaskModel[], taskType: 'ref_doc' | 'doc_up' | 'delivery', currentUser: any, leadValuerId: number): {
        task: TpTaskModel,
        userOfTask: boolean,
        canBeShown: boolean,
        otherTasks?: TpTaskModel[]
    } {
        if (tasks.length > 0) {
            const initialTask = tasks[0];
            const userOfTask = currentUser.id == initialTask.user_id || currentUser.id == leadValuerId 
                // || currentUser.id == this.pm.id;
            let newTask = new TpTaskModel();
            newTask.clear();
            newTask.id = initialTask.id;
            newTask.point = 0;
            newTask.max_point = 0;
            newTask.task_id = initialTask.task_id;
            newTask.tp_id = initialTask.tp_id;
            newTask.task_name = this._getTaskName(tasks.length, taskType);
            newTask.user_id = initialTask.user_id;
            newTask.date = initialTask.date;
            newTask.user_name = initialTask.user_name;
            newTask.assignee = initialTask.assignee;
            newTask.updated_at = initialTask.updated_at;
            newTask.order_num = initialTask.order_num;

            let completed = 0;
            let lastUpdatedAt = this.typesUtilsService.getDateFromString(initialTask.updated_at);
            for (let index = 0; index < tasks.length; index++) {
                const task = tasks[index];
                newTask.point += task.point;
                newTask.max_point += task.max_point;

                if (task.point == task.max_point) {
                    completed += 1;
                }

                const updatedAt = this.typesUtilsService.getDateFromString(task.updated_at);
                if (lastUpdatedAt.getTime() < updatedAt.getTime()) {
                    lastUpdatedAt = updatedAt;
                }
                newTask.details = this._getTaskDetail(tasks.length, completed, taskType);
            }
            newTask.updated_at = this.typesUtilsService.getDateStringFromDate(lastUpdatedAt);
            return {
                task: newTask,
                userOfTask,
                canBeShown: newTask.point == newTask.max_point && currentUser.id != newTask.user_id,
                otherTasks: taskType == 'delivery' ? tasks : null
            };
        }
        return null
    }

    private _getTaskName(tasks_num: number, taskType: 'ref_doc' | 'doc_up' | 'delivery'): string {
        const isPlural = tasks_num > 1 ? 's' : '';
        switch (taskType) {
            case 'ref_doc': 
                // return 'Documents upload (External References)';
                return 'Investigation';
            case 'doc_up':
                return `Document${isPlural} upload (signed report${isPlural})`;
            case 'delivery':
                return `Report${isPlural} delivery to client`
        }
    }

    private _getTaskDetail(tasks_num: number, completed: number, taskType: 'ref_doc' | 'doc_up' | 'delivery'): string {
        const isPlural = tasks_num > 1 ? 's' : '';
        switch (taskType) {
            case 'ref_doc':
                // return `${tasks_num} document${isPlural} to upload (${completed})`;
                return 'External References'
            case 'doc_up':
                return `${tasks_num} signed report${isPlural} to upload (${completed})`;
            case 'delivery':
                return `${tasks_num} signed report${isPlural} to deliver (${completed})`;
        }
    }

    private _reportGenerationStatus(taskCompletionMap: Map<string, boolean[]>) {
        const reportingTask = taskCompletionMap.get('reporting');
        if (!reportingTask) {
            this.canGenerateDraftReportSubject.next(false);
            this.canGenerateValuationReportSubject.next(false);
            return;
        }
        if (reportingTask.filter(t => !t).length == 0) {
            this.canGenerateDraftReportSubject.next(true);
            this.canGenerateValuationReportSubject.next(true);
            // Who knows maybe we might need it again
            // const auditTasks = taskCompletionMap.get('audit_trail')
            // if (!auditTasks) {
            //     this.canGenerateValuationReportSubject.next(true);
            // } else {
            //     if (auditTasks.filter(t => !t).length == 0) {
            //         this.canGenerateValuationReportSubject.next(true);
            //     } else {
            //         this.canGenerateValuationReportSubject.next(false);
            //     }
            // }
        } else {
            this.canGenerateValuationReportSubject.next(false);
            this.canGenerateDraftReportSubject.next(false);
        }
    }
    current_task(item) {
        return this.todo_ids.includes(item.id)
    }
    upcoming_task(item) {
        return this.upcoming_ids.includes(item.id)
    }

    public addBlankScenario(valuationTask: any) {
        this.store.dispatch(new AddValuationTaskScenario({id: valuationTask.task.id, index: valuationTask.task.task_meta.original_task_index, type: 'blank'}))
    }

    public duplicateScenario(valuationTask: any) {
        this.store.dispatch(new AddValuationTaskScenario({id: valuationTask.task.id, index: valuationTask.task.task_meta.original_task_index, type: 'duplicate'}))
    }

    public deleteScenario(valuationTask: any) {
        this.store.dispatch(new DeleteValuationTaskScenario({id: valuationTask.task.id}));
    }

    public onMethodSelect(event: any, taskRow: any) {
        this.store.dispatch(new UpdateMethodOfValuationTask({task_id: taskRow.task.id, method_id: event}))
    }

    private convertValuationTasksForDisplay(
        tasks: {
            id: number,
            details: string,
            user_id: number, 
            point: number, 
            max_point: number, 
            scenario: {
                original_task_id: number,
                scenario_number: number,
                original_task_index: number
            }
            tp_meta: {
                base_of_value_id: number,
                base_of_value_name: string,
                approach_to_value_id: number, 
                approach_to_value_name: string,
                method_to_value_id: number,
                method_to_value_name: string
            }}[], 
        currentUser: {id: number}, 
        allTasks: any
    ): any[] {
        const _originalTasks = tasks.filter(t => {
            if (t.scenario == null) {
                return true;
            }
            if (t.id == t.scenario.original_task_id) {
                return true;
            }
            return false;
        })
        const originalTasks: Array<{
            id: number,
            details: string,
            user_id: number, 
            point: number, 
            max_point: number, 
            scenario: {
                original_task_id: number,
                scenario_number: number,
                original_task_index: number
            }
            tp_meta: {
                base_of_value_id: number,
                base_of_value_name: string,
                approach_to_value_id: number, 
                approach_to_value_name: string,
                method_to_value_id: number,
                method_to_value_name: string
            }
        }> = Array.from({length: _originalTasks.length})
        const temp = []
        _originalTasks.forEach(t => {
            if (t.scenario) {
                originalTasks[t.scenario.original_task_index] = t
            } else {
                temp.push(t)
            }
        })
        for (let i = 0; i < originalTasks.length; i++) {
            if (originalTasks[i] == undefined) {
                originalTasks[i] = temp.shift()
            }
        }

        const taskRows = tasks.map(t => {
            const userOfTask = currentUser.id == t.user_id || currentUser.id == this.leadValuerId || currentUser.id == this.pm.id
            const itsOriginalTaskId = t.scenario ? t.scenario.original_task_id : t.id
            const relatedScenarios = tasks.filter(t => {
                if (t.scenario) {
                    return t.scenario.original_task_id == itsOriginalTaskId
                }
                return t.id == itsOriginalTaskId;
            })
            const originalTask = originalTasks.find(ot => ot.id == itsOriginalTaskId);
            const originalTaskIndex = originalTasks.findIndex(ot => ot.id == itsOriginalTaskId)
            const index = originalTask && originalTask.scenario ? originalTask.scenario.original_task_index : originalTaskIndex
            const taskRow = {
                canReach: userOfTask && canReachValuationTask(allTasks),
                canBeShown: t.point == t.max_point && currentUser.id != t.user_id ,
                task: {
                    ...t,
                    task_name: 'Valuation',
                    task_detail:`V${index + 1}` + (t.scenario ? ` (scenario ${t.scenario.scenario_number}) - ` : ' - ') ,
                    details: `${t.tp_meta.base_of_value_name.replace(/value/ig, '')} - ${t.tp_meta.approach_to_value_name} (${t.tp_meta.method_to_value_name})`,
                    order: 4,
                    task_meta: {
                        approach: t.tp_meta.approach_to_value_id == ApproachToValue.Income ? 'income' : 'other',
                        original_task_index: index,
                        scenario_number: t.scenario ? t.scenario.scenario_number : 1,
                        show_add_scenario: 
                            relatedScenarios.length < 3 &&
                            ((t.tp_meta.approach_to_value_id == ApproachToValue.Market && t.tp_meta.method_to_value_id ==MetodToValue.ComparableTransaction)
                            || (t.tp_meta.approach_to_value_id == ApproachToValue.Income)),
                        show_delete_scenario: t.scenario != null,
                    },
                },
                lastInCategory: false,
                disable_all_buttons: t.tp_meta.method_to_value_id == null
            }
            return taskRow
        })

        taskRows.sort((a, b) => {
            if (a.task.task_meta.original_task_index == b.task.task_meta.original_task_index) {
                return a.task.task_meta.scenario_number - b.task.task_meta.scenario_number
            }
            return a.task.task_meta.original_task_index > b.task.task_meta.original_task_index ? 1 : -1
        })

        const lastRow = taskRows[taskRows.length - 1]
        taskRows[taskRows.length - 1] = {
            ...lastRow,
            lastInCategory: true
        }

        return taskRows;
    }

    private groupTasks(taskDataSource: {task: any, canReach: boolean, canBeShown: boolean, otherTasks?: any[], tooltip?: string, lastInCategory?: boolean}[]) {
        const grouped: Record<string, typeof taskDataSource> = taskDataSource.reduce((acc, item) => {
            const group = item.task.task_name;
            if (acc[group]) {
                acc[group].push(item)
            } else {
                acc[group] = [item]
            }
            return acc
        }, {})
        const converted = Object.values(grouped).map((items, i) => {
            return items.map(item => ({...item, rowColor: i%2 == 0 ? '#9693b90c' : 'white'}))
        })
        return _.flatten(converted).map(item => {
            const group = item.task.task_name;
            const itemsInGroup = taskDataSource.filter(item => item.task.task_name == group);
            const isFirstItem = itemsInGroup.findIndex(_item => _item.task.id == item.task.id)
            return {
                ...item,
                rowSpan: isFirstItem == 0 ? itemsInGroup.length : 0
            };
        })
    }
}


export function setTaskCompletion(taskCompletionMap: Map<string, boolean[]>, taskId: number, status: boolean): Map<string, boolean[]> {
    let completionLst: boolean[];
    let keyStr: string = '';
    if (taskId == 0) {
        keyStr = "document"
    } else if (taskId == 1) {
        keyStr = "investigation";
    } else if (taskId == 2) {
        keyStr = "valuation";
    } else if (taskId == 3) {
        keyStr = "reporting";
    } else if (taskId == 4) {
        keyStr = "delivery";
    } else if (taskId == 10){
        keyStr = "signed_doc"
    } else if (taskId == 11) {
        keyStr = "audit_trail"
    } else if (taskId == 12) {
        keyStr = "due-diligence";
    } else if (taskId == Tasks.LogisticsTask) {
        keyStr = 'logistic'
    } else if (taskId == Tasks.LandmarksTask) {
        keyStr = 'landmark'
    } else if (taskId == Tasks.ReportDesignTask) {
        keyStr = 'report-design'
    } else {
        keyStr = null;
    }

    if (!keyStr) {
        return taskCompletionMap;
    }

    completionLst = taskCompletionMap.get(keyStr);
    if (!completionLst) {
        return taskCompletionMap;
    }
    completionLst.push(status);
    taskCompletionMap.set(keyStr, completionLst);

    return taskCompletionMap;
}

export function computeTargetPropertyTaskPercentage(taskCompletionMap: Map<string, boolean[]>): number {
    const documentTasks = taskCompletionMap.get("document");
    const otherInvestigationTasks = getSectionTasks(["logistic", "landmark", "investigation"], taskCompletionMap);
    if (documentTasks.length > 0) {
        otherInvestigationTasks.push(documentTasks.every(t => t == true))
    }
    const investigationPercentage = computeSectionPercentage(
        otherInvestigationTasks,
        30
    )
    const valuationPercentage = computeSectionPercentage(
        getSectionTasks(["valuation"], taskCompletionMap),
        40
    )
    const reportingPercentage = computeSectionPercentage(
        getSectionTasks(["report-design", "reporting"], taskCompletionMap),
        20
    )
    const uploadPercentage = computeSectionPercentage(
        getSectionTasks(["signed_doc"], taskCompletionMap),
        5
    )
    const deliveryPercentage = computeSectionPercentage(
        getSectionTasks(["delivery"], taskCompletionMap),
        5
    )
    return Math.round(investigationPercentage + valuationPercentage + reportingPercentage + uploadPercentage + deliveryPercentage)
}

export function getSectionTasks(task_keys: string[], task_map: Map<string, boolean[]>): boolean[] {
    return task_keys.map(key => task_map.get(key)).reduce((acc, tasks) => [...acc, ...tasks], [])
}

export function computeSectionPercentage(tasks: boolean[], percentage: number): number {
    if (tasks.length == 0) {
        return percentage;
    }
    const total = tasks.length
    const completedTotal = tasks.filter(task => task == true).length
    return percentage * completedTotal / total
}

export function computeTotalPercentageOfTask(maxVal: number, completionLst: boolean[]): number {
    if (completionLst.length == 0) {
        return 0;
    }
    const forEachTask = maxVal / completionLst.length;
    return completionLst
        .map(completionStatus => completionStatus ? forEachTask : 0)
        .reduce(((a, b) => a + b), 0);
}

function canReachVal(taskCompletionMap: Map<string, boolean[]>): boolean {
    const notCompletedDocumentTasks = taskCompletionMap.get("document").filter(t => !t);
    const notCompletedInvestigationTasks = taskCompletionMap.get("investigation").filter(t => !t);
    return notCompletedDocumentTasks.length == 0 && notCompletedInvestigationTasks.length == 0;
}

function canReachReporting(taskCompletionMap: Map<string, boolean[]>): boolean {
    const notCompletedValTasks = taskCompletionMap.get("valuation").filter(t => !t);
    return notCompletedValTasks.length == 0;
}

function canReachSigned(taskCompletionMap: Map<string, boolean[]>): boolean {
    let canReach = false;

    const notCompletedReportTasks = taskCompletionMap.get("reporting").filter(t => !t);
    const notCompletedReportDesignTasks = taskCompletionMap.get('report-design').filter(t => !t)
    canReach = notCompletedReportTasks.length == 0 && notCompletedReportDesignTasks.length == 0;

    if (taskCompletionMap.has("audit_trail")) {
        const auditTasks = taskCompletionMap.get('audit_trail').filter(t => !t);
        canReach = canReach && auditTasks.length == 0;
    }

    return canReach;
}

function canReachDelivery(tasks: TpTaskModel[], order_num: number): boolean {
    const draftSigned = tasks.find(t => t.order_num == order_num);
    if (!draftSigned) {
        return false;
    }
    return draftSigned.point == draftSigned.max_point;
}

function canReachDeliveryTask(tasks: any[], task_id: Tasks, second_id: DocumentTypes) {
    const task = tasks.find(t => {
        return t.task_id == task_id && t.second_id == second_id
    })
    if (!task) {
        return false;
    }
    return task.point == task.max_point
}

function canReachInvestigationTask(tasks: TpTaskModel[]): boolean {
    const task = tasks.find(t => t.task_id == Tasks.LogisticsTask)
    if (!task) {
        return true;
    }
    return task.point == task.max_point;
}

function canReachValuationTask(tasks: TpTaskModel[]): boolean {
    const task = tasks.find(t => t.task_id == Tasks.InvestigationTask)
    if (!task) {
        return false
    }
    return task.point == task.max_point;
}

interface ValuationTask {
    id: number,
    tp_meta: {
        base_of_value_id: number
    }
}

// Base of Value Id 7 is Liquidation Value
export function moveLiquidationValuations<T extends ValuationTask>(rows: T[]): T[] {
    const liquidationRows = rows.filter(row => 
        row.tp_meta.base_of_value_id == 7 
    )
    const otherRowsGrouped = rows
        .filter(row => 
            row.tp_meta.base_of_value_id != 7 
        )
        .reduce(
            (acc: {[key: string]: T[]}, row) => {
                const baseOfValueId = row.id.toString()
                if (acc[baseOfValueId]) {
                    acc[baseOfValueId].push(row)
                } else {
                    acc[baseOfValueId] = [row]
                }
                return acc
            },
            {}
        )
    const keys: string[] = []
    for (const key in otherRowsGrouped) {
        keys.push(key)
    }

    if (keys.length == 0) {
        return liquidationRows
    }

    const resultRows: T[] = []
    if (keys.length == 1) {
        otherRowsGrouped[keys[0]].forEach(row => resultRows.push(row))
        liquidationRows.forEach(row => resultRows.push(row))
    } else {
        keys.forEach((key, i) => {
            if (i == 1) {
                liquidationRows.forEach(row => resultRows.push(row))
            } 
            otherRowsGrouped[key].forEach(row => resultRows.push(row))
        })
    }
    return resultRows
}