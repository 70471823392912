import { Lease, PropertyRevenue, CalculationMeta, HeadlineToEffectiveMeta } from "../../types";
import { IncomeValuationProcess } from "../../types/valuation-process";

export function setHeadlineToEffectiveMeta(
  process: IncomeValuationProcess, 
  {uuid, kind, meta}: {
    uuid: string, 
    kind: 'lease' | 'property-revenue'
    meta: HeadlineToEffectiveMeta
  }
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: kind === 'lease' ? updateLeaseMeta(process.data.leases, uuid, meta) : process.data.leases,
      propertyRevenues: kind === 'property-revenue' ? updatePropertyRevenues(process.data.propertyRevenues, uuid, meta) : process.data.propertyRevenues
    }
  }
}

function updateLeaseMeta(leases: Lease[], uid: string, meta: HeadlineToEffectiveMeta): Lease[] {
  return leases.map(item => {
    if (item.uid === uid) {
      return {
        ...item,
        headlineToEffectiveMeta: meta
      }
    }
    return item
  })
}
function updatePropertyRevenues(revenues: PropertyRevenue[], uid: string, meta: HeadlineToEffectiveMeta): PropertyRevenue[] {
  return revenues.map(item => {
    if (item.uid === uid) {
      return {
        ...item,
        headlineToEffectiveMeta: meta
      }
    }
    return item
  })
}

function updateLeaseModelAssumptions(leases: Lease[], uid: string, meta: CalculationMeta): Lease[] {
  return leases.map(item => {
    if (item.uid === uid) {
      return {
        ...item,
        calculationMeta: meta
      }
    }
    return item
  })
}
function updatePropertyRevenuesModelAssumptions(revenues: PropertyRevenue[], uid: string, meta: CalculationMeta): PropertyRevenue[] {
  return revenues.map(item => {
    if (item.uid === uid) {
      return {
        ...item,
        calculationMeta: meta
      }
    }
    return item
  })
}

export function updateModelAssumptions(
  process: IncomeValuationProcess,
  { uuid, kind, meta }: {
    uuid: string,
    kind: 'lease' | 'property-revenue',
    meta: CalculationMeta
  }
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: kind === 'lease' ? updateLeaseModelAssumptions(process.data.leases, uuid, meta) : process.data.leases,
      propertyRevenues: kind === 'property-revenue' ? updatePropertyRevenuesModelAssumptions(process.data.propertyRevenues, uuid, meta) : process.data.propertyRevenues
    }
  }
}